<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Freezing Point Depression: Report Sheet</h2>
    </v-row>

    <v-row class="mb-2">
      a) Complete the data table below using data collected during the experiment and values
      calculated therefrom. Note that the mass of water in the first row of the table is referring
      to the mass used in preparing the sugar solution.
    </v-row>

    <v-simple-table class="mb-8" style="max-width: 529px">
      <thead>
        <tr>
          <td style="font-weight: bold">Property</td>
          <td style="font-weight: bold; text-align: center">Value</td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Mass of water in solution (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massWater"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Mass of solute (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massSolute"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Molar mass of solute (g/mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molarMass"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Moles of solute (mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molSolute"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Molality (mol/kg)</td>
          <td>
            <calculation-input
              v-model="inputs.molality"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\text{T}_\text{f}~$" /> of pure water (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.tfPure"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\text{T}_\text{f}~$" /> of solution (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.tfSolution"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\Delta\text{T}_\text{f}~$" /> (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.deltaTf"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="Theoretical $\Delta\text{T}_\text{f}~$" /> (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.theoreticalDeltaTf"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\%~\text{Error}$" /></td>
          <td>
            <calculation-input
              v-model="inputs.pctError"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-2">
      b) Please upload all graphs and data for this experiment in a single file using the input
      field below.
    </p>

    <p><b>Accepted file types</b>: PDF, JPG, PNG, XLSX, XLS</p>

    <v-row class="mb-5 pl-4">
      <v-file-input v-model="dataUpload" multiple />
    </v-row>

    <v-row class="mb-2">
      c) In the space below, discuss your results and potential sources of error for the experiment.
    </v-row>

    <v-row class="mb-5">
      <s-textarea
        v-model="inputs.sourcesOfError"
        outlined
        :counter="true"
        rows="10"
        :maxlength="2000"
      />
    </v-row>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'FreezingPointDepressionWaterReportSheetV2',
  components: {
    STextarea,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        unknownNumber: null,

        massWater: null,
        massSolute: null,
        molarMass: null,
        molSolute: null,
        molality: null,
        tfPure: null,
        tfSolution: null,

        deltaTf: null,
        theoreticalDeltaTf: null,
        pctError: null,

        sourcesOfError: null,
      },
      dataUpload: [],
    };
  },
  computed: {
    attachments() {
      return [...this.dataUpload];
    },
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
